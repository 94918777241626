type ROUTE_FUNCTION = () => string;

/**
 * Route entry.
 */
export interface RouteEntry<TYPE extends ROUTE_FUNCTION = (...args) => string> {
	/** if True, this route is not public */
	readonly authenticated: boolean;
	/** a url route builder function. 🚨 Arguments MUST BE OPTIONAL 🚨 */
	readonly build: TYPE;
	/** a url route matcher to detect complex UNAUTHENTICATED routes, and prevent false posivite matches.
	 * If undefined, the matching will be done with 'startsWith'
	 * and build() output without argument */
	readonly matcher?: RegExp;
}

/**
 * Please define a route entry here.
 */
export interface AppRoutes {
	/** version page */
	readonly version: RouteEntry;
	/** home page */
	readonly home: RouteEntry;
	/** search engine page */
	readonly search: RouteEntry;
	/** search engine for scan creation page */
	readonly searchScan: RouteEntry;
	/** profile form update */
	readonly profile: RouteEntry<(open?: boolean) => string>;
	/** login page */
	readonly authLogin: RouteEntry;
	/** signup page */
	readonly authSignup: RouteEntry;
	/** signup link activation page */
	readonly authSignupActivate: RouteEntry;
	/** signup success message page */
	readonly authSignupSuccess: RouteEntry<(email?: string) => string>;
	/** password reset request form */
	readonly authPasswordResetRequest: RouteEntry;
	/** password link password reset page (with form) */
	readonly authPasswordResetConfirm: RouteEntry;
	/** password success update message page */
	readonly authPasswordResetSuccess: RouteEntry<(email?: string) => string>;
	/** help page */
	readonly help: RouteEntry<(open?: boolean) => string>;
	/** scan (paidEvaluation) page */
	readonly scans: RouteEntry;
	/** scan (paidEvaluation) page */
	readonly scanCreate: RouteEntry<(testIds?: number[]) => string>;
	/** scan (paidEvaluation) page refine */
	readonly scanRefine: RouteEntry<(id?: number) => string>;
	/** scan (paidEvaluation) hydrogeologist expert edition */
	readonly hydroExpert: RouteEntry<(id?: number) => string>;
	/** scan (paidEvaluation) page detail */
	readonly scanDetail: RouteEntry<(id?: number) => string>;
	/** label (evaluationSet) page */
	readonly labels: RouteEntry;
	/** label (evaluationSet) page refine */
	readonly labelRefine: RouteEntry<(id?: number) => string>;
	/** label (evaluationSet) page detail */
	readonly labelDetail: RouteEntry<(id?: number) => string>;
	/** a test (freeEvaluation) page */
	readonly test: RouteEntry;
	/** credit page */
	readonly credits: RouteEntry;
	/** Scan Faisa page */
	readonly scanFaisa: RouteEntry;
	/** list project archived page */
	readonly listArchived: RouteEntry<(open?: boolean) => string>;
	/** list test archived page */
	readonly testArchived: RouteEntry<(open?: boolean) => string>;
	/** design system */
	readonly staffDesignSystem: RouteEntry;
	readonly staffDesignSystemV2: RouteEntry;
	/** page of Error 403 */
	readonly error403: RouteEntry;
	/** page of maintenance */
	readonly maintenance: RouteEntry;
	/** page for parcels' id */
	readonly staffParcel: RouteEntry;
	/** page for building staff builder */
	readonly staffBuilding: RouteEntry;
	/** page for building staff builder */
	readonly faisaDetails: RouteEntry<(id?: number) => string>;
}

export type RouteNames = keyof AppRoutes;

/**
 * The concrete list of routes of the application.
 * 🚧 If you want to add a route, please refer to {@link AppRoutes} first.
 */
export const Routes: AppRoutes = {
	version: {
		authenticated: false,
		build: () => '/version'
	},
	home: {
		authenticated: true,
		build: () => '/home'
	},
	search: {
		authenticated: true,
		build: () => '/search'
	},
	searchScan: {
		authenticated: true,
		build: () => '/search/scan',
		matcher: /^\/search\/extended$/
	},
	profile: {
		authenticated: true,
		build: (open?: boolean) => `/profile${open ? '?open=true' : ''}`
	},
	test: {
		authenticated: true,
		build: () => '/test',
		matcher: /^\/test$/
	},
	scans: {
		authenticated: true,
		build: () => '/scans',
		matcher: /^\/scans$/
	},
	scanCreate: {
		authenticated: true,
		build: (testIds?: number[]) =>
			`/scans/create${testIds ? `?sel=${testIds.join(',')}` : ''}`,
		matcher: /^\/scans\/create$/
	},
	scanDetail: {
		authenticated: true,
		build: (id?: number) => `/scans/${id}/details`,
		matcher: /^\/scans\/\d+\/details$/
	},
	scanRefine: {
		authenticated: true,
		build: (id?: number) => `/scans/${id}/refining`,
		matcher: /^\/scans\/\d+\/refining$/
	},
	hydroExpert: {
		authenticated: true,
		build: (id?: number) => `/scans/${id}/edit`,
		matcher: /^\/scans\/\d+\/edit$/
	},
	labels: {
		authenticated: true,
		build: () => '/labels',
		matcher: /^\/labels$/
	},
	labelDetail: {
		authenticated: true,
		build: (id?: number) => `/labels/${id}/details`,
		matcher: /^\/labels\/\d+\/details$/
	},
	labelRefine: {
		authenticated: true,
		build: (id?: number) => `/labels/${id}/refining`,
		matcher: /^\/labels\/\d+\/refining$/
	},
	credits: {
		authenticated: true,
		build: () => '/credits',
		matcher: /^\/credits$/
	},
	scanFaisa: {
		authenticated: true,
		build: () => '/scanFaisa',
		matcher: /^\/scanFaisa$/
	},
	authLogin: {
		authenticated: false,
		build: () => '/auth/login'
	},
	authSignup: {
		authenticated: false,
		build: () => '/auth/signup',
		matcher: /^\/auth\/signup$/
	},
	authSignupActivate: {
		authenticated: false,
		build: () => '/auth/signupActivate'
	},
	authSignupSuccess: {
		authenticated: false,
		build: (email?: string) =>
			`/auth/signupSuccess${email ? `?email=${encodeURI(email)}` : ''}`
	},
	authPasswordResetConfirm: {
		authenticated: false,
		build: () => '/auth/passwordResetConfirm'
	},
	authPasswordResetRequest: {
		authenticated: false,
		build: () => '/auth/passwordResetRequest'
	},
	authPasswordResetSuccess: {
		authenticated: false,
		build: (email?: string) =>
			`/auth/passwordResetSuccess${email ? `?email=${encodeURI(email)}` : ''}`
	},
	// TODO: remove
	staffDesignSystem: {
		authenticated: false,
		build: () => '/staff/designSystem'
	},
	staffDesignSystemV2: {
		authenticated: false,
		build: () => '/staff/designSystemV2'
	},
	help: {
		authenticated: false,
		build: (open?: boolean) => `/help${open ? '?open=true' : ''}`
	},
	listArchived: {
		authenticated: true,
		build: (open?: boolean) => `/profileListArchived${open ? '?open=true' : ''}`
	},
	testArchived: {
		authenticated: true,
		build: (open?: boolean) => `/profileTestArchived${open ? '?open=true' : ''}`
	},
	error403: {
		authenticated: true,
		build: () => '/error/403'
	},
	maintenance: {
		authenticated: false,
		build: () => '/maintenance'
	},
	staffParcel: {
		authenticated: true,
		build: () => '/staff/parcel'
	},
	staffBuilding: {
		authenticated: true,
		build: () => '/staff/building'
	},
	faisaDetails: {
		authenticated: true,
		build: (id?: number) => `/scanFaisa/${id}/faisaDetails`
	}
};

export const unauthenticatedRoutes = new Set<RouteEntry<any>>(
	Object.values(Routes).filter(value => !value.authenticated)
);
