import {
	Center,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalOverlay,
	ModalProps,
	Stack
} from '@chakra-ui/react';
import {GeoButton} from 'components/forms';
import {GeoHeaderText} from 'components/text/GeoHeaderText';
import React from 'react';

export interface ModalNotiProps extends ModalProps {
	isLoading?: boolean;
	isDisabled?: boolean;
	onOpen?: any;
	onClick?: () => void;
	children: React.ReactNode;
	isOpen: boolean;
	icon: JSX.Element;
	onClose: () => void;
	size?: string;
	title?: string;
	button?: string;
}

export const ModalNoti: React.FC<ModalNotiProps> = ({
	isLoading,
	isDisabled,
	onOpen,
	isOpen,
	size,
	title,
	icon,
	children,
	button,
	onClose,
	onClick,
	...props
}: ModalNotiProps) => {
	return (
		<>
			<Modal isCentered isOpen={isOpen} size={size} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton color='geoBlue.500' data-cy='modal-button-close' />
					<ModalBody mt='50px'>
						<Center width='100%'>
							<Stack direction='column' spacing={3} width='100%'>
								<Flex width='100%' justifyContent='center'>
									<Flex
										height='20%'
										maxHeight='200px'
										width='20%'
										maxWidth='200px'
									>
										{icon}
									</Flex>
								</Flex>
								<Flex width='100%' justifyContent='center'>
									<Flex>
										<GeoHeaderText fontSize='xl'>{title}</GeoHeaderText>
									</Flex>
								</Flex>
								<Flex justifyContent='center'>{children}</Flex>
							</Stack>
						</Center>
					</ModalBody>
					<ModalFooter justifyContent='center'>
						<GeoButton
							data-cy='modal-button-confirm'
							isLoading={isLoading}
							isDisabled={isDisabled}
							onClick={onClick}
						>
							{button}
						</GeoButton>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};
