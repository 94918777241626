import {
	FormControl,
	FormControlProps,
	FormHelperText,
	FormErrorMessage,
	FormLabel,
	TextProps,
	Text,
	Box,
	Flex
} from '@chakra-ui/react';
import {useTranslation} from 'modules/i18n';

import React from 'react';

export interface FormInputContainerProps extends FormControlProps {
	label?: string;
	helperText?: string;
	errorMessage?: string;
	helperProps?: TextProps;
	labelWidth?: string;
}
export const FormInputContainer: React.FC<FormInputContainerProps> = ({
	label,
	helperText,
	errorMessage,
	children,
	helperProps = {},
	...formControlProps
}: FormInputContainerProps) => {
	const {t} = useTranslation();
	return (
		<FormControl
			{...formControlProps}
			isInvalid={errorMessage !== null && errorMessage !== '&nbsp;'}
		>
			{label && <FormLabel my={1}>{label}</FormLabel>}
			{children}
			{helperText && (
				<FormHelperText {...helperProps}>{helperText}</FormHelperText>
			)}
			{errorMessage && (
				<FormErrorMessage my={1}>{t(errorMessage)}</FormErrorMessage>
			)}
		</FormControl>
	);
};
export const FormInputFlexRowContainer: React.FC<FormInputContainerProps> = ({
	label,
	helperText,
	errorMessage,
	children,
	width,
	labelWidth,
	helperProps = {},
	...formControlProps
}: FormInputContainerProps) => {
	const {t} = useTranslation();

	return (
		<FormControl
			{...formControlProps}
			display={'flex'}
			alignItems={'center'}
			isInvalid={errorMessage !== null && errorMessage !== '&nbsp;'}
		>
			{label && (
				<FormLabel>
					<Text fontWeight='semibold' w={labelWidth || '15em'}>
						{label}
					</Text>
				</FormLabel>
			)}
			<Flex flexDirection='column'>
				<Box width={width}>{children}</Box>

				{errorMessage && (
					<FormErrorMessage my={1}>{t(errorMessage)}</FormErrorMessage>
				)}
				{helperText && (
					<FormHelperText {...helperProps}>{helperText}</FormHelperText>
				)}
			</Flex>
		</FormControl>
	);
};
