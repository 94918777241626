import {extendTheme} from '@chakra-ui/react';

import {Breakpoints} from '@chakra-ui/theme-tools';

const breakpoints: Breakpoints<{
	sm: string;
	md: string;
	lg: string;
	xl: string;
	'2xl': string;
}> = {
	// 🚧 if you change this, please change accordingly useResponsive.tsx file
	// for consistency and backward compatibility
	base: '0em',
	sm: '320px',
	md: '812px',
	lg: '1024px',
	xl: '3000px',
	'2xl': '3860px'
};

export const geoTheme = extendTheme({
	breakpoints,
	config: {
		initialColorMode: 'light',
		useSystemColorMode: false
	},
	colors: {
		geoBlue: {
			100: '#F7FBFC',
			200: '#D4F3FC',
			300: '#7FCEF4',
			400: '#00BBFF29',
			500: '#00BBFF',
			// To keep color consistency with firefox for scrollbars.
			600: '#2596be',
			// To keep color consistency with firefox for scrollbars.
			700: '#005a87'
		},
		geoGrey: {
			100: '#F8F8F8',
			200: '#0000001F',
			300: '#7070704D',
			400: '#7070701A',
			500: '#707070',
			600: '#3C3732'
		},
		geoGreen: {
			100: '#B2FD95',
			200: '#94ED73',
			300: '#75DB4F',
			400: '#50D190',
			500: '#26B26B',
			600: '#038E48'
		},
		geoRed: {
			100: '#F78377',
			300: '#F26657',
			500: '#E44230',
			600: '#FF0000'
		},
		geoOrange: {
			50: '#FFF5E6',
			100: '#FFD9B3',
			200: '#FFBF80',
			300: '#FFA64D',
			400: '#FF8C1A',
			500: '#E67300',
			600: '#CC5C00',
			700: '#B24700',
			800: '#993300',
			900: '#802600'
		},
		geoYellow: {
			50: '#FFFFCC',
			100: '#FFEF77',
			200: '#FFEB52',
			300: '#F6F60B',
			500: '#FFE417',
			600: '#FFCF03'
		}
	},
	components: {
		Checkbox: {
			defaultProps: {
				colorScheme: 'white'
			},
			variants: {
				round: {
					control: {
						borderRadius: '50%',
						borderColor: 'grey',
						borderWidth: '2px',
						bg: 'transparent',
						_before: {
							content: "''",
							display: 'block',
							borderRadius: '50%',
							borderColor: 'white',
							width: '50%',
							height: '50%',
							position: 'absolute',
							top: '25%',
							left: '25%'
						},
						_checked: {
							borderColor: 'geoBlue.500',
							_before: {
								backgroundColor: 'geoBlue.500'
							}
						}
					},
					_icon: {
						display: 'none'
					}
				}
			}
		},
		Button: {
			variants: {
				solid: {
					// Default variant for buttons
					justifyContent: 'center',
					borderRadius: '3xl',
					py: '6',
					px: '9',
					alignItems: 'center',
					color: 'white',
					bg: 'geoBlue.500',
					fontSize: 'sm',
					fontWeight: 'bold',
					boxShadow:
						'0.5px 0.5px 0 rgba(0,0,0,0.4), -0.5px -0.5px 0 rgba(255,255,255,1)',
					_hover: {bg: 'geoBlue.600'},
					_active: {bg: 'geoBlue.700'}
				},
				outline: {
					// Common with solid
					borderRadius: '3xl',
					justifyContent: 'center',
					alignItems: 'center',
					fontSize: 'sm',
					boxShadow:
						'0.5px 0.5px 0 rgba(0,0,0,0.4), -0.5px -0.5px 0 rgba(255,255,255,1)',
					// Outline
					borderColor: 'geoBlue.500',
					border: '1px',
					py: '3',
					px: '5',
					fontWeight: 'semibold',
					color: 'geoBlue.500',
					bg: 'geoBlue.100',
					shadow: 'null'
				},
				ghost: {
					// Common with solid
					borderRadius: '3xl',
					justifyContent: 'center',
					alignItems: 'center',
					fontSize: 'sm',
					py: '6',
					px: '9',
					// Common with outline
					borderColor: 'geoBlue.500',
					fontWeight: 'semibold',
					color: 'geoBlue.500',
					shadow: 'null',
					// Ghost
					bg: 'white',
					border: '',
					boxShadow: ''
				}
			}
		}
	}
});

export type GeoTheme = typeof geoTheme;
