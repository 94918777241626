import {
	Center,
	FormControl,
	FormLabel,
	Stack,
	Switch,
	SwitchProps
} from '@chakra-ui/react';
import {GeoIconText} from 'components/text/GeoIconText';
import React from 'react';
import {useTranslation} from '../../modules/i18n';
import {UseFormMethods} from 'react-hook-form';
import {TestForm} from 'modules/search/hooks';

export interface GeoSwitchProps extends SwitchProps {
	isMobile?: boolean;
	byAddress: boolean;
	formCtx: UseFormMethods<TestForm>;
}

export const GeoSwitch: React.FC<GeoSwitchProps> = ({
	isMobile,
	byAddress,
	formCtx
}: GeoSwitchProps) => {
	const {t} = useTranslation();
	// const {changeType} = useSearch();
	const renderIcon = (src: string, isActive: boolean) =>
		isActive ? `/images/${src}-active.svg` : `/images/${src}.svg`;

	const typeText = (isActive: boolean) =>
		isActive
			? {fontWeight: 'semibold', color: 'black'}
			: {fontWeight: 'medium', color: 'geoGrey.500'};
	const renderSwitch = () => (
		<FormControl>
			<Stack
				height='max-content'
				width='max-content'
				direction='row'
				spacing={2}
				mt={isMobile ? '2em' : '1em'}
			>
				<FormLabel onClick={() => formCtx.setValue('mode', 'coordinates')}>
					<GeoIconText
						active={typeText(byAddress)}
						src={renderIcon('picAccueil', byAddress)}
						label={t('search.address')}
					/>
				</FormLabel>
				<Center px={isMobile ? 1 : 5}>
					<Switch
						size='lg'
						isChecked={!byAddress}
						checked={!byAddress}
						data-cy={`switch-form-search`}
						onChange={e => {
							formCtx.setValue(
								'mode',
								e.target.checked ? 'coordinates' : 'address'
							);
						}}
					/>
				</Center>
				<Center>
					<FormLabel onClick={() => formCtx.setValue('mode', 'address')}>
						<GeoIconText
							active={typeText(!byAddress)}
							src={renderIcon('pin', !byAddress)}
							label={t('search.coordinates')}
						/>
					</FormLabel>
				</Center>
			</Stack>
		</FormControl>
	);

	return renderSwitch();
};
