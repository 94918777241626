import {TextProps} from '@chakra-ui/react';
import {StyledSubheaderText} from 'components/theme/StyledSubheaderText';
import React from 'react';

export interface GeoTextProps extends TextProps {}

export const GeoHeaderText: React.FC<GeoTextProps> = (props: GeoTextProps) => (
	<>
		<StyledSubheaderText
			as={props.as}
			fontSize={props.fontSize ?? '2xl'}
			color={props.color}
			width={props.width}
			{...props}
		>
			{props.children}
		</StyledSubheaderText>
	</>
);
