/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import {
	useForm,
	UseFormMethods,
	FormProvider,
	UseFormOptions,
	FieldValues,
	UnpackNestedValue,
	FieldName
} from 'react-hook-form';
export {useFormContext} from 'react-hook-form';

/**
 * All validation function must comply to this interface
 * If a validator return a string, it means that the field is in error
 */
export type Validator<T> = (
	val: T,
	methods: Pick<UseFormMethods, 'getValues'>
) => string | true;

export type FormProperties<
	Values extends FieldValues,
	Context extends object
> = React.PropsWithChildren<
	UseFormOptions<Values, Context> & {
		resetOnSubmit?: boolean;
		resetWithSubmittedValues?: boolean;
		onSubmit?: (
			data: UnpackNestedValue<Values>,
			setValue: (
				name: FieldName<FormProperties<Values, Context>>,
				value: unknown,
				config?: Partial<{
					shouldValidate: boolean;
					shouldDirty: boolean;
				}>
			) => void
		) => void;
	}
>;
/**
 * An easy access to react-hook-form methods
 * You can use the same options that you would use with `useForm` but as props for this component.
 *
 * Please use `useFormContext` inside the nested component to get and change values.
 */
export const Form = <
	Values extends FieldValues = FieldValues,
	Context extends object = object
>(
	// eslint-disable-next-line indent
	props: FormProperties<Values, Context>
	// eslint-disable-next-line indent
) => {
	const methods = useForm<FormProperties<Values, Context>>(props);

	return (
		// see : https://react-hook-form.com/api/useformcontext
		<FormProvider {...methods}>
			<form
				style={{width: 'inherit', height: 'inherit'}}
				onSubmit={methods.handleSubmit<Values>(
					(data: UnpackNestedValue<Values>) => {
						props.onSubmit(data, methods.setValue);
						if (props.resetOnSubmit) {
							if (props.resetWithSubmittedValues) {
								methods.reset(data);
							} else {
								methods.reset();
							}
						}
					}
				)}
			>
				{props.children}
			</form>
		</FormProvider>
	);
};
