import React from 'react';
import {ChakraProvider} from '@chakra-ui/react';
import {AuthenticationProvider} from '../modules/authentication/provider';
import {AuthenticationDispatch} from '../modules/authentication/provider/AuthenticationDispatch';
import {I18nProvider} from '../modules/i18n';
import {geoTheme} from '../theme';

import {LibraryStateProvider} from 'utils/ondemandLibrary';
import {ClientReady} from 'utils/nextComponents';
import {MockActivator} from 'utils/MockActivator';
import {Cookies} from 'components/forms/Cookies';
import {MaintenanceContext} from 'modules/authentication/provider/MaintenanceContext';
import {ConsoleActivator, ConsoleProvider} from 'utils/console';
import {LocalizedNavigationProvider} from 'modules/navigation/hooks';
import {DevInfo} from 'components/DevInfo';
import {InsufficientCreditModalProvider} from 'components/forms/InsufficientCreditModal';

interface MyAppProps {
	Component: React.FC;
	pageProps: {};
}

/**
 * MyApp is the default app entry. It defines the Chakra provider, the I18n provider, the router provider and the auth provider.
 * @param Components
 * @param pageProps Data provided server side and pass to the page as static data.
 * @see https://nextjs.org/docs/advanced-features/custom-app
 */
export default function MyApp({Component, pageProps}: MyAppProps) {
	return (
		<>
			<ChakraProvider theme={geoTheme}>
				<ConsoleProvider>
					<ConsoleActivator>
						<LocalizedNavigationProvider>
							<MockActivator>
								<ClientReady>
									<I18nProvider>
										<DevInfo />
										<MaintenanceContext>
											<LibraryStateProvider>
												<AuthenticationProvider>
													<AuthenticationDispatch>
														<InsufficientCreditModalProvider>
															<Cookies />
															{/* Ajouter les contextes des états navbar */}
															<Component {...pageProps} />
														</InsufficientCreditModalProvider>
													</AuthenticationDispatch>
												</AuthenticationProvider>
											</LibraryStateProvider>
										</MaintenanceContext>
									</I18nProvider>
								</ClientReady>
							</MockActivator>
						</LocalizedNavigationProvider>
					</ConsoleActivator>
				</ConsoleProvider>
			</ChakraProvider>
		</>
	);
}
