import React from 'react';
import {Box, HStack, Icon, InputProps} from '@chakra-ui/react';
import {FormInputContainer} from 'components/form/FormInputContainer';
import {ValidatorWithMethods} from 'components/form/types';
import {StyledSearch} from 'components/theme/StyledSearch';
import {useTheme} from '@emotion/react';
import get from 'lodash/get';
import {SearchIcon} from '@chakra-ui/icons';
import {useFormInput} from 'components/form/utils';

export interface GeoSearchProps extends InputProps {
	name: string;
	placeholder?: string;
	validation?: ValidatorWithMethods<any>;
}

export const GeoSearch: React.FC<GeoSearchProps> = ({
	name,
	placeholder,
	validation,
	...props
}: GeoSearchProps) => {
	const {hasError, validationInContext, methods} = useFormInput({
		name,
		validation
	});
	const theme = useTheme();

	return (
		<Box
			w='14rem'
			py={'4px'}
			border={props.border ?? '1px'}
			borderColor='#00BBFF'
			borderRadius='3xl'
		>
			<HStack>
				<Icon
					as={SearchIcon}
					color={props.color ?? 'geoBlue.500'}
					marginLeft='10%'
				/>
				<FormInputContainer
					isInvalid={hasError}
					errorMessage={hasError?.message ?? '&nbsp;'}
				>
					<StyledSearch
						ref={methods.register({
							validate: validationInContext,
							valueAsNumber: false,
							valueAsDate: false
						})}
						color={props.color ?? get(theme, 'colors.geoBlue.500')}
						name={name}
						w={props.w}
						px={props.px ?? '0'}
						placeholder={placeholder}
						fontStyle={props.fontStyle}
						fontSize='sm'
						fontWeight={props.fontWeight ?? 'bold'}
						isInvalid={hasError}
						sx={{fontSize: props.fontSize ?? 'xs'}}
						border={props.border ?? 'none'}
						focusBorderColor={hasError ? 'label.fail' : 'inherit'}
						{...props}
						bg={props.bg ?? ''}
					/>
				</FormInputContainer>
			</HStack>
		</Box>
	);
};
