import {Button, ButtonProps} from '@chakra-ui/react';
import React, {MouseEventHandler} from 'react';

export interface GeoButtonProps extends ButtonProps {
	/** loading state */
	isLoading?: boolean;
	/** on click handler */
	onClick?: MouseEventHandler<HTMLButtonElement>;
	/** Icon button */
	icon?: string;
	/* Button styling */
	variant?: string;
	isDisabled?: boolean;
}

/**
 * Customized button
 *
 * @param props
 * @returns a react component.
 */
export const GeoButton: React.FC<GeoButtonProps> = ({
	onClick,
	children,
	icon,
	isLoading = false,
	variant = 'solid',
	isDisabled,
	...props
}: GeoButtonProps) => (
	<>
		{/* TODO: move those styles to theme */}

		<Button
			width={props.width ?? undefined}
			isLoading={isLoading ?? undefined}
			ml={props.ml ?? undefined}
			variant={variant}
			{...props}
			onClick={onClick}
			isDisabled={isDisabled}
			textAlign={'center'}
		>
			{children}
		</Button>
	</>
);
export const GeoStyledButton: React.FC<GeoButtonProps> = ({
	onClick,
	children,
	icon,
	isLoading = false,
	...props
}: GeoButtonProps) => (
	<>
		{/* TODO: move those styles to theme */}

		<Button
			width={props.width ?? undefined}
			justifyContent={props.justifyContent ?? 'center'}
			borderRadius={props.borderRadius ?? 'xl'}
			borderColor={props.borderColor}
			border={props.border}
			isLoading={isLoading ?? undefined}
			py={props.py ?? '3'}
			px={props.px ?? '9'}
			ml={props.ml ?? undefined}
			variant={props.variant}
			alignItems='center'
			color={props.color ?? 'white'}
			bg={props.bg ?? '#00BBFF'}
			fontSize='sm'
			fontWeight={props.fontWeight ?? 'bold'}
			leftIcon={props.leftIcon}
			rightIcon={props.rightIcon}
			size={props.size}
			boxShadow={
				props.boxShadow ??
				// eslint-disable-next-line react/jsx-indent
				'0.5px 0.5px 0 rgba(0,0,0,0.4), -0.5px -0.5px 0 rgba(255,255,255,1)'
			}
			sx={props.sx}
			_hover={{bg: '#5ac0e6'}}
			{...props}
			onClick={onClick}
		>
			{children}
		</Button>
	</>
);
