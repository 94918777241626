import {TextProps} from '@chakra-ui/react';
import {StyledSubtitleText} from 'components/theme/StyledSubtitleText';
import React from 'react';

export interface GeoTextProps extends TextProps {}

export const GeoSubtitleText: React.FC<GeoTextProps> = (
	props: GeoTextProps
) => (
	<>
		<StyledSubtitleText fontSize={props.fontSize ?? 'xs'} {...props}>
			{props.children}
		</StyledSubtitleText>
	</>
);
