import styled from '@emotion/styled';
import {Input} from '@chakra-ui/react';

export const StyledSearch = styled(Input)`
	&::placeholder {
		font-style: bold;
		color: ${props => props.color};
		text-align: end;
		font-size: 0.85rem;
	}
	&:focus::placeholder {
		color: transparent;
	}
`;
