import {ValidatorWithMethods} from 'components/form/types';
import {useFormContext} from 'react-hook-form';
import get from 'lodash/get';
import {useCallback} from 'react';

export const useFormInput = (opts: {
	name: string;
	validation?: ValidatorWithMethods<any>;
}) => {
	const methods = useFormContext();
	const hasError = get(methods.errors, opts.name);

	const hookFormValidation = useCallback(
		(value: any) => {
			const result = opts.validation
				? opts.validation(value, methods)
				: undefined;
			return result;
		},
		[opts.validation, methods]
	);

	return {
		methods,
		hasError,
		validationInContext: hookFormValidation
	};
};
