import {useBreakpoint} from '@chakra-ui/react';

/**
 * a hook that efficiently notify if we are in a mobile viewport
 */
export const useResponsive = () => {
	const _bp = useBreakpoint();
	// const [isMobile, isTablette, isDesktop, isLarge] = useMediaQuery([
	// 	'(max-width: 812px)',
	// 	'(max-width: 1024px) and (min-width: 813px)',
	// 	'(max-width: 3000px) and (min-width: 1025px)',
	// 	'(max-width; 3860px) and (min-width: 3001px)'
	// ]);

	return {
		isMobile: ['sm', 'md'].includes(_bp),
		isTablette: 'lg' === _bp,
		isDesktop: 'xl' === _bp,
		isLarge: '2xl' === _bp
	};
};
