import styled from '@emotion/styled';
import {Input} from '@chakra-ui/react';

export const StyledInput = styled(Input)`
	&::placeholder {
		font-style: italic;
		color: #707070;
	}
`;

export const StyledGeoSurfaceInput = styled(Input)`
	&::placeholder {
		font-style: italic;
		color: #707070;
		font-size: 15px;
	}
	text-align: right;
	&:hover {
		border-color: #00bbff;
		outline: none;
	}
`;
