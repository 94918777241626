/**
 * This file contains API function for login modules.
 */
import {User} from 'modules/profile/types';
import {http} from 'utils/http';

// ROUTE
export const apiUserRoute = () => '/users/me/';
// API FUNCTION
export const apiCurrentUser = () => {
	// console.log(apiUserRoute());
	return http.get<User>(apiUserRoute());
};
