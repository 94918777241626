import React, {createContext, useContext} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'modules/i18n';
import {Flex, Img, useDisclosure} from '@chakra-ui/react';
import {GeoSubtitleText} from 'components/text/GeoSubtitleText';
import {useCurrentUser} from 'modules/authentication/provider/hook';
import {ModalNoti} from 'components/banner/ModalNoti';

interface InsufficientCreditModalProps {
	hasCredit: (creditType: string) => boolean;
}

const InsufficientCreditModalContext =
	createContext<InsufficientCreditModalProps>(undefined);

export const InsufficientCreditModalProvider = ({
	children
}: {
	children: React.ReactNode;
}) => {
	const {isOpen, onOpen, onClose} = useDisclosure();
	const {t} = useTranslation();
	const {user} = useCurrentUser();

	const hasCredit = (creditType: string): boolean => {
		if (user.credit[creditType] <= 0) {
			onOpen();
			return false;
		}
		return true;
	};

	return (
		<InsufficientCreditModalContext.Provider value={{hasCredit}}>
			{children}
			<ModalNoti
				isOpen={isOpen}
				size='xl'
				icon={<Img src='/images/pictoCredits-modal.svg' />}
				title={t('errors.lack_credit')}
				button={t('creditPage.contact')}
				onClick={() => {
					window.location.href = `mailto:contact@geosophy.io?subject=${t(
						'creditPage.mail_subject'
					)}`;
				}}
				onClose={onClose}
			>
				<Flex textAlign='center'>
					<GeoSubtitleText fontSize='md' color='geoBlue.500'>
						{t('errors.no_credit')}
					</GeoSubtitleText>
				</Flex>
			</ModalNoti>
		</InsufficientCreditModalContext.Provider>
	);
};

export const useInsufficientCreditChecker = () =>
	useContext(InsufficientCreditModalContext);

InsufficientCreditModalProvider.propTypes = {
	children: PropTypes.node.isRequired
};
