import {Box, Flex, Img, Stack, TextProps, VStack} from '@chakra-ui/react';
import {StyledSubtitleText} from 'components/theme/StyledSubtitleText';
import React from 'react';
import {useResponsive} from 'utils/useResponsive';

export interface GeoIconTextProps extends TextProps {
	direction?: 'top' | 'side';
	src?: string;
	active?: {fontWeight: string; color: string};
	label?: string | React.ReactNode;
}
export const GeoIconText: React.FC<GeoIconTextProps> = (
	props: GeoIconTextProps
) => {
	const {isMobile} = useResponsive();
	const _direction = props.direction ?? 'side';

	const renderContent = () => {
		return (
			<>
				<Img
					src={props.src}
					width='2.5em'
					height='2.5em'
					minWidth={isMobile ? '25px' : '30px'}
					minHeight={isMobile ? '25px' : '30px'}
				/>
				<Flex alignItems='center'>
					<StyledSubtitleText
						as={props.as}
						// fontWeight={props.active ? 'semibold' : 'medium'}
						fontWeight={props.active.fontWeight}
						fontSize='sm'
						// color={props.active ? 'black' : 'geoGrey.500'}
						color={props.active.color}
					>
						{props.label}
					</StyledSubtitleText>
				</Flex>
			</>
		);
	};

	const renderTopContent = () => {
		return (
			<>
				<Img
					src={props.src}
					width='5em'
					height='5em'
					minWidth={isMobile ? '25px' : '30px'}
					minHeight={isMobile ? '25px' : '30px'}
				/>
				{props.label instanceof String ? (
					<StyledSubtitleText
						fontWeight={props.active.fontWeight}
						fontSize='sm'
						color={props.active.color}
						as={props.as}
						justifyContent='center'
					>
						{props.label}
					</StyledSubtitleText>
				) : (
					<Box
						fontWeight={props.active.fontWeight}
						fontSize='sm'
						color={props.active.color}
						as={props.as}
					>
						{props.label}
					</Box>
				)}
			</>
		);
	};

	if (_direction === 'top') {
		return (
			<VStack
				spacing={isMobile ? '.5rem' : '.7em'}
				marginLeft='2'
				marginRight='2'
			>
				{renderTopContent()}
			</VStack>
		);
	}

	return (
		<Stack direction='row' spacing={isMobile ? '.5rem' : '.7em'}>
			{renderContent()}
		</Stack>
	);
};
