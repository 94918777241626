import {Box, Checkbox, CheckboxProps, FormLabel, Stack} from '@chakra-ui/react';
import {ValidatorWithMethods} from 'components/form/types';
import React from 'react';
import {useTranslation} from '../../modules/i18n';
import {useFormContext} from '../../utils/form';

export interface GeoCheckboxProps extends CheckboxProps {
	name: string;
	label?: string | JSX.Element;
	validation?: ValidatorWithMethods<any>;
}

export const GeoCheckbox: React.FC<GeoCheckboxProps> = ({
	name,
	value,
	validation,
	label,
	checked,
	...props
}: GeoCheckboxProps) => {
	const {errors, register} = useFormContext();
	const {t} = useTranslation();

	return (
		<Box>
			<FormLabel
				sx={{
					fontSize: 14,
					color: 'primary'
				}}
			>
				<Stack direction='row' spacing={3}>
					<Box mt={0.5}>
						<Checkbox
							variant={props.variant}
							ref={register({validate: validation as any}) as any}
							size={props.size}
							value={value}
							name={name}
							borderColor='geoBlue.500'
							iconColor='geoBlue.500'
							isDisabled={props.isDisabled}
							isChecked={props.isChecked}
							{...props}
						/>
					</Box>
					<Box>{label ?? ''}</Box>
				</Stack>
			</FormLabel>
			{/* TODO: with the right component  */}
			{errors[name] && <div>{t(errors[name]?.message)}</div>}
		</Box>
	);
};
