import {Button, ButtonProps} from '@chakra-ui/react';
import React, {MouseEventHandler} from 'react';

interface GeoCardButtonProps extends ButtonProps {
	/** loading state */
	isLoading?: boolean;
	/** on click handler */
	onClick?: MouseEventHandler<HTMLButtonElement>;
	variant?: 'wide' | 'tight';
}

/**
 * Customized button
 *
 * @param props
 * @returns a react component.
 */

export const GeoCardButton: React.FC<GeoCardButtonProps> = ({
	onClick,
	children,
	isLoading = false,
	variant = 'wide',
	...props
}: GeoCardButtonProps) => (
	<>
		<Button
			variant='outline'
			sx={{whiteSpace: 'normal', wordWrap: 'break-word'}}
			mx={props.mx}
			py={variant === 'wide' ? 3 : 1}
			width={variant === 'wide' ? '12rem' : 'max-content'}
			height='max-content'
			borderRadius='md'
			px='4'
			onClick={onClick}
			isDisabled={props.isDisabled}
		>
			{children}
		</Button>
	</>
);
