import {Box, HStack, RadioProps} from '@chakra-ui/react';
import {ValidatorWithMethods} from 'components/form/types';
import React from 'react';
import {useTranslation} from '../../modules/i18n';
import {useFormContext} from '../../utils/form';

export interface GeoRadioProps extends RadioProps {
	name?: string;
	value?: string;
	label?: string | JSX.Element;
	validation?: ValidatorWithMethods<any>;
	dataCy?: string;
}

export const GeoRadio: React.FC<GeoRadioProps> = ({
	name,
	value,
	validation,
	label,
	...props
}: GeoRadioProps) => {
	const {errors, register} = useFormContext();
	const {t} = useTranslation();

	// colorScheme='cyan'
	// size='md'
	return (
		<HStack>
			{/* TODO: with the right component Radio */}
			<input
				ref={register({validate: validation as any}) as any}
				id={props.id}
				name={name}
				value={value}
				type='radio'
				disabled={props.isDisabled}
				style={{borderWidth: '0.1', color: 'red'}}
				data-cy={props.dataCy}
			/>
			<Box>
				<label color='cyan' htmlFor={props.id}>
					{label ?? ''}
				</label>
			</Box>

			{/* TODO: with the right component  */}
			{errors[name] && <div>{t(errors[name]?.message)}</div>}
		</HStack>
	);
};
