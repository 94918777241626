/** a forms input that communicates its value to a wrapping form context */
import React from 'react';
import {InputProps} from '@chakra-ui/react';
import {
	FormInputContainer,
	FormInputContainerProps
} from 'components/form/FormInputContainer';
import {ValidatorWithMethods} from 'components/form/types';
import {StyledGeoSurfaceInput, StyledInput} from 'components/theme/StyledInput';
import styled from '@emotion/styled';
import {Input, InputGroup, InputLeftAddon} from '@chakra-ui/react';
import {useFormInput} from 'components/form/utils';

export interface GeoInputProps extends InputProps {
	name: string;
	label?: string;
	helperText?: string;
	placeholder?: string;
	containerProps?: Partial<FormInputContainerProps>;
	valueAs?: 'number' | 'default' | 'date';
	validation?: ValidatorWithMethods<any>;
	unit?: string;
	variant?: 'default' | 'right-unit' | 'left-unit';
}

export const GeoInput: React.FC<GeoInputProps> = ({
	name,
	label,
	placeholder,
	helperText,
	validation,
	valueAs = 'default',
	containerProps = {},
	...props
}: GeoInputProps) => {
	const {hasError, validationInContext, methods} = useFormInput({
		name,
		validation
	});

	// const _selectedVariant = () => {
	// 	if (variant === 'right-unit') {
	// 		return <>TODO</>
	// 	} else if (variant === 'left-unit') {
	// 		return <>TODO</>
	// 	}
	return (
		<FormInputContainer
			isInvalid={hasError}
			label={label}
			helperText={helperText}
			errorMessage={hasError?.message ?? '&nbsp;'}
			{...containerProps}
		>
			<StyledInput
				ref={methods.register({
					validate: validationInContext,
					valueAsNumber: valueAs === 'number',
					valueAsDate: valueAs === 'date'
				})}
				name={name}
				w={props.w}
				defaultValue={props.defaultValue}
				placeholder={placeholder}
				fontStyle={props.fontStyle}
				isInvalid={hasError}
				borderRadius='md'
				fontWeight={props.fontWeight}
				sx={{fontSize: props.fontSize ?? 'xs'}}
				border={props.border ?? '1px'}
				borderColor={props.borderColor ?? 'geoGrey.500'}
				focusBorderColor={hasError ? 'label.fail' : 'inherit'}
				{...props}
			/>
			{/* {_selectedVariant()} */}
		</FormInputContainer>
	);
};

export const GeoSurfaceInput: React.FC<GeoInputProps> = ({
	name,
	label,
	placeholder,
	helperText,
	validation,
	valueAs = 'default',
	containerProps = {},
	...props
}: GeoInputProps) => {
	const {hasError, validationInContext, methods} = useFormInput({
		name,
		validation
	});
	return (
		<FormInputContainer
			isInvalid={hasError}
			label={label}
			helperText={helperText}
			errorMessage={hasError?.message ?? '&nbsp;'}
			{...containerProps}
		>
			<StyledGeoSurfaceInput
				ref={methods.register({
					validate: validationInContext,
					valueAsNumber: valueAs === 'number',
					valueAsDate: valueAs === 'date'
				})}
				name={name}
				w={props.w}
				defaultValue={props.defaultValue}
				placeholder={placeholder}
				fontStyle={props.fontStyle}
				isInvalid={hasError}
				borderRadius='md'
				fontWeight={props.fontWeight}
				sx={{fontSize: props.fontSize ?? 'xs'}}
				border={props.border ?? '1px'}
				borderColor={props.borderColor ?? 'geoGrey.500'}
				focusBorderColor={hasError ? 'label.fail' : 'inherit'}
				{...props}
			/>
		</FormInputContainer>
	);
};
const StyledRightInput = styled(Input)`
	&::placeholder {
		font-style: italic;
		color: #707070;
		font-size: 15px;
	}
	text-align: right;
	border: 1px solid #707070;
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	border-left: 0;

	&:hover {
		border-color: #00bbff;
		outline: none;
	}
`;
const StyledInputLeftAddon = styled(InputLeftAddon)`
	border: 1px solid #707070;
	border-right: 0;
	font-size: 15px;

	/* &:hover {
		border-color: #00bbff;
		outline: none;
	} */
`;
const StyledInputGroup = styled(InputGroup)`
	&:hover {
		border-color: #00bbff;
		outline: none;
	}
`;

export const GeoConsumpInput: React.FC<GeoInputProps> = ({
	name,
	label,
	placeholder,
	helperText,
	validation,
	valueAs = 'default',
	containerProps = {},
	...props
}: GeoInputProps) => {
	const {hasError, validationInContext, methods} = useFormInput({
		name,
		validation
	});
	return (
		<FormInputContainer
			// isInvalid={hasError}
			// label={label}
			// helperText={helperText}
			// errorMessage={hasError?.message ?? '&nbsp;'}
			{...containerProps}
		>
			<StyledInputGroup>
				<StyledInputLeftAddon
					marginLeft={'10px'}
					bg={'white'}
					color={'#707070'}
					h={'48px'}
				/>
				<StyledRightInput
					size='lg'
					w={'143px'}
					type='text'
					bg={'white'}
					ref={methods.register({
						validate: validationInContext,
						valueAsNumber: valueAs === 'number',
						valueAsDate: valueAs === 'date'
					})}
					name={name}
					defaultValue={props.defaultValue}
					placeholder='Indiquez'
					fontStyle={props.fontStyle}
					isInvalid={hasError}
					borderRadius='md'
					fontWeight={props.fontWeight}
					sx={{fontSize: props.fontSize ?? 'xs'}}
					border={props.border ?? '1px'}
					borderColor={props.borderColor ?? 'geoGrey.500'}
					focusBorderColor={hasError ? 'label.fail' : 'inherit'}
					{...props}
				/>

				<style jsx>{`
					.customizedInput {
						text-align: right;
						margin-left: 58px;
						width: 70px;
					}

					&:hover {
						outline: none;
					}
					input::placeholder {
						font-style: italic;
						color: #707070;
						font-size: 15px;
					}
					.unit {
						font-size: 15px;
						margin-left: 24px;
						color: #707070;
					}
				`}</style>
			</StyledInputGroup>
		</FormInputContainer>
	);
};
